import React, { useCallback, useEffect, useState } from "react";
import ProtectedRoutesLayout from "../../../layout/ProtectedRoutesLayout/ProtectedRoutesLayout";
import PrimaryHeading from "../../../components/primitives/PrimaryHeading";
import { useDispatch, useSelector } from "react-redux";
import styles from "./Unity.css";
import { Unity, useUnityContext } from "react-unity-webgl";
import PrimaryButton from "../../../components/button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import useMutationInstance from "../../../hooks/useMutationInstance";
import { logout } from "../../../store/authSlice";
import toast from "react-hot-toast";

const URL = `${process.env.REACT_APP_BACKEND}/auth/logout`;

const AuthLanding = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const { user, token } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    unityProvider,
    isLoaded,
    loadingProgression,
    sendMessage,
    addEventListener,
    removeEventListener,
    requestFullscreen,
    unload, // Function to unload Unity
  } = useUnityContext({
    loaderUrl: "/unitybuild.loader.js",
    dataUrl:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/unitybuild.data.br",
    // "/unitybuild.data.br",
    codeUrl:
      "https://taara-quest.s3.eu-central-1.amazonaws.com/unity-build-assets/unitybuild.wasm.br",
    // "/unitybuild.wasm.br",
    frameworkUrl: "/unitybuild.framework.js",
    webglContextAttributes: {
      preserveDrawingBuffer: true,
    },
  });

  const onError = (error) => {
    const errorResponse = error?.response?.data;
    // console.log(errorResponse);
    toast.error(errorResponse?.message || "Something went wrong");
  };

  const onSuccess = (response) => {
    dispatch(logout());
    navigate("/login");
  };
  const { mutate, isPending } = useMutationInstance({
    onSuccess,
    onError,
  });

  const handleSignOut = () => {
    mutate({ url: URL, data: { token } });
  };

  const handleSetAuthToken = useCallback(
    (token) => {
      if (isLoaded === false || isPlaying === true) {
        return;
      }
      setIsPlaying(true);
      sendMessage("ReactUnityCommunicator", "SetAuthToken", user.id);
    },
    [isLoaded, isPlaying, sendMessage]
  );

  const handleInitiateLogin = useCallback((tag) => {
    console.log(`[React]InitiateLogin:`, tag);
  }, []);

  const handleInitiateLogout = useCallback((tag) => {
    console.log(`[React]Logout:`, tag);
    handleSignOut();
  }, []);

  const handleClickFullscreen = () => {
    if (isLoaded === false) {
      return;
    }
    requestFullscreen(true);
  };

  useEffect(() => {
    if (user && token && isLoaded && !isPlaying) {
      console.log("setting token to unity");
      handleSetAuthToken(token);
    }
  }, [user, token, isLoaded, isPlaying, handleSetAuthToken]);

  useEffect(() => {
    if (addEventListener) {
      addEventListener("InitiateLogin", handleInitiateLogin);
      addEventListener("InitiateLogout", handleInitiateLogout);
    }

    return () => {
      if (removeEventListener) {
        removeEventListener("InitiateLogin", handleInitiateLogin);
        removeEventListener("InitiateLogout", handleInitiateLogout);
      }
    };
  }, [
    handleInitiateLogin,
    addEventListener,
    removeEventListener,
    handleInitiateLogout,
  ]);

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      console.log("Unloading Unity...");
      sendMessage("ReactUnityCommunicator", "StopProcessing"); // Communicate with Unity to stop ongoing tasks
      unload().catch((error) =>
        console.error("Error while unloading Unity:", error)
      );
    };
  }, [unload, sendMessage]);

  return (
    <ProtectedRoutesLayout>
      <div className="flex flex-col gap-6 text-center">
        <PrimaryHeading heading={`Hi ${user?.name}`} />
        <PrimaryHeading heading="WELCOME TO YOUR GROWTH SPACE" />
      </div>
      <div className={styles.container}>
        <div className={styles.unityWrapper}>
          {isLoaded === false && (
            <div className={styles.loaderContainer}>
              {/* Circular Spinner */}
              <div className={styles.spinner}></div>

              {/* Progress Bar */}
              <div className={styles.loadingBar}>
                <div
                  className={styles.loadingBarFill}
                  style={{ width: `${loadingProgression * 100}%` }}
                />
              </div>

              {/* Loading Text */}
              <div className="w-full h-[300px] flex justify-center items-center">
                <div className="w-24 h-24 flex justify-center items-center border-2 border-black rounded-full">
                  <p className={styles.loadingText}>
                    {Math.round(loadingProgression * 100)}%
                  </p>
                </div>
              </div>
            </div>
          )}

          <div className="w-full py-14 flex justify-center">
            <Unity
              unityProvider={unityProvider}
              style={{
                display: isLoaded ? "block" : "none",
                height: "400px",
                width: "800px",
              }}
            />
          </div>
          {isLoaded && (
            <div className="w-full flex justify-center pb-14">
              <PrimaryButton
                text="Full screen"
                onClick={handleClickFullscreen}
              />
            </div>
          )}
        </div>
      </div>
    </ProtectedRoutesLayout>
  );
};

export default AuthLanding;
