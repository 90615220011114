import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../images/logo.svg"; // Import your logo
import SignOutButton from "./SignOutButton";
import useScreenDetector from "../../hooks/useScreenDetector";
import { useNavigate } from "react-router-dom";
const sideBarNavItems = [
  { label: "Home", link: "/", disabled: false },
  { label: "Custom Quest", link: "/myquest", disabled: true },
  { label: "Profile", link: "/profile", disabled: false },
  { label: "Billing", link: "/billing", disabled: true },
];

const ProtectedRoutesLayout = ({ children }) => {
  const location = useLocation();
  const { isTablet } = useScreenDetector();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
const navigate = useNavigate()
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  if (isTablet) {
    return (
      <div className="h-screen w-screen flex items-center justify-center p-4 text-center">
        This content is compatible with desktop only, please switch to desktop
        mode for better user experience.
      </div>
    );
  }

  return (
    <>
      {/* Toggle button for mobile, aligned to the right */}
      <div className="flex justify-between items-center w-full sm:hidden">
      <div className="flex justify-center mb-6 ">
          <img
            src={logo} // Replace with your logo path
            alt="Brand Logo"
            className="h-12 w-auto mt-3 ml-2"
          />
        </div>
        <button
          onClick={toggleSidebar}
          type="button"
          className="inline-flex items-center p-2 mr-3 -mt-3 ms-auto text-sm text-gray-800 rounded-lg sm:hidden"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="w-6 h-6"
            aria-hidden="true"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clipRule="evenodd"
              fillRule="evenodd"
              d="M2 4.75A.75.75 0 012.75 4h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 4.75zm0 10.5a.75.75 0 01.75-.75h7.5a.75.75 0 010 1.5h-7.5a.75.75 0 01-.75-.75zM2 10a.75.75 0 01.75-.75h14.5a.75.75 0 010 1.5H2.75A.75.75 0 012 10z"
            ></path>
          </svg>
        </button>
      </div>

      <div className="flex flex-col sm:flex-row ">
        {/* Sidebar */}
        <aside
          className={`h-screen bg-white transition-transform ${
            isSidebarOpen ? "translate-x-0" : "-translate-x-full"
          } sm:translate-x-0 sm:w-[20%] border border-r-2 fixed top-0 left-0 z-10 sm:z-auto`}
          aria-label="Sidebar"
        >
          <div className="h-full px-3 py-4 overflow-y-auto">
            {/* Brand Logo on the left */}
            <div className="flex justify-start mb-6 mobile:hidden cursor-pointer" onClick={()=>navigate('/')}   >
              <img src={logo} alt="Brand Logo" className="h-12 w-auto" />
            </div>
            <ul className="space-y-2 font-medium mt-[87px]">
              {sideBarNavItems.map((item, key) => (
                <li key={key}>
                  {!item.disabled ? (
                    <Link
                      to={item.link}
                      className={`flex items-center p-2 rounded-lg group ${
                        location.pathname === item.link
                          ? "text-black" // Active route: black text color
                          : "text-[#9A9A9A] hover:text-black"
                      }`}
                    >
                      <span className="ms-3">{item.label}</span>
                    </Link>
                  ) : (
                    <div className="flex items-center p-2 rounded-lg group text-[#9A9A9A] cursor-not-allowed">
                      <span className="ms-3">{item.label}</span>
                    </div>
                  )}
                </li>
              ))}
              <li className="hidden mobile:flex">
                <SignOutButton />
              </li>
            </ul>
          </div>
        </aside>

        {/* Main Content */}
        <div className="w-full sm:w-[80%] sm:ml-auto">
          {/* Header */}
          <div className="sticky top-0 bg-white z-20 flex justify-end h-32 px-16 py-10 items-center mobile:hidden">
            {/* Logout Button */}
            <SignOutButton />
          </div>

          {/* Content */}
          {children}
        </div>
      </div>
    </>
  );
};

export default ProtectedRoutesLayout;
