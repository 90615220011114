import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";
import { useSelector } from "react-redux";
const navLinks = [
  { label: "Taara", link: "/taara" },
  { label: "About", link: "/about" },
  { label: "Blog", link: "/blog" },
  { label: "Ambassador", link: "/ambassador" },
  { label: "Assessment", link: "/assessment" },
  { label: "FAQ", link: "/faq" },
];

const Navbar = ({ signupAction }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const { user, token } = useSelector((state) => state.auth);

  const [buttonClassName, setButtonClassname] = useState(
    "navbarMenuDropdownClosed"
  );

  const handleClick = () => {
    setOpen((value) => !value);
  };

  useEffect(() => {
    if (open) {
      setButtonClassname("navbarMenuDropdown");
    } else {
      setButtonClassname("navbarMenuDropdownClosed");
    }
  }, [open]);

  const handleNavigation = () => {
    if (location.pathname !== "/") {
      localStorage.setItem("shouldScrollToSignup", true);
      navigate("/");
    } else {
      signupAction && signupAction();
    }
  };

  return (
    <nav>
      <div className="navbarContainer">
        <div
          className="navbarLogo"
          onClick={() => {
            navigate("/");
          }}
        ></div>
        <div className="navbarLinks">
          {navLinks.map((navLink, idx) => (
            <Link key={idx} to={navLink.link} className="navbarLink">
              {navLink.label}
            </Link>
          ))}
        </div>
        {/* <a href="#signup-form">
          <div className="accountButton" onClick={handleNavigation}>
            Sign Up
          </div>
        </a> */}
        <Link to={!token?"/signup":"/dashboard"}>
          <div className="accountButton" onClick={handleNavigation}>
            {token ? 'Dashboard':'Sign Up'}
          </div>
        </Link>
      </div>

      {}
      <div className="mobileNavbarContainer relative">
        <div
          className="navbarLogo"
          onClick={() => {
            navigate("/");
          }}
        ></div>
        <div className="hamburgerMenu" onClick={handleClick}>
          ☰ {}
        </div>
        {open && (
          <div className="absolute top-0 left-0 w-screen h-screen bg-white flex flex-col justify-between px-7 py-10">
            <div className="w-full flex flex-col gap-16">
              <div
                className="w-full flex justify-end"
                onClick={() => setOpen(false)}
              >
                X
              </div>
              <div className="flex flex-col gap-6">
                {navLinks.map((navLink) => (
                  <Link
                    to={navLink.link}
                    onClick={() => setOpen(false)}
                    className="text-2xl text-primaryGray"
                  >
                    {navLink.label}
                  </Link>
                ))}

                <button className="w-full text-center py-5 bg-primaryBlue text-white text-xl rounded-lg">
                  Sign Up
                </button>
              </div>
            </div>
            <p className="text-center text-xs ">Taara Quest @2024</p>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
